<template>
  <div class="submit_succ">
    <span>提交成功</span>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      // 假设你要跳转到 '/home' 路径
      // this.$router.back.catch((err) => {
      //    处理可能出现的错误，比如路由已经被守卫拦截
      //   console.error(err);
      // });
      // window.history.back();
    }, 3000);
  },
};
</script>

<style scoped>
.submit_succ {
  width: 1120px;
  min-height: 800px;
  background-color: white;
  margin: 20px auto;
}

.submit_succ span {
  width: 200px;
  height: 70px;
  color: black;
  font-size: 20px;
}
</style>
